import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Fade, { FadeProps } from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Slide, { SlideProps } from '@mui/material/Slide';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Context,
  createContext,
  FC,
  forwardRef,
  memo,
  PropsWithChildren,
  ReactNode,
  ReactPortal,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

import { Backdrop } from '@/components/Dialog/Backdrop';
import { DISABLE_CLICK_AWAY_CLASS } from '@/constants/global';
import { nullReturnNoop } from '@/utils/noop';

import { WidthType } from './types';

type OpenConfirmationType = (content: ReactNode, options?: ConfirmationsOptions) => void;
type CloseConfirmationType = () => void;

type ModalContextType = {
  content: ReactNode;
  openModal: OpenConfirmationType;
  closeModal: CloseConfirmationType;
  setModalContainerStyles?: (styles: string) => void;
  setAnchorModalContainerStyles?: (styles: string) => void;
  ModalAnchor: () => JSX.Element | null;
};

type ModalConfirmationContextType = {
  content: ReactNode;
  openConfirmation: OpenConfirmationType;
  closeConfirmation: CloseConfirmationType;
  ConfirmationAnchor: () => JSX.Element | null;
};

export const ConfirmationContext = createContext<ModalConfirmationContextType>({
  content: null,
  openConfirmation: nullReturnNoop,
  closeConfirmation: nullReturnNoop,
  ConfirmationAnchor: nullReturnNoop,
});
export const ModalContext = createContext<ModalContextType>({
  content: null,
  openModal: nullReturnNoop,
  closeModal: nullReturnNoop,
  ModalAnchor: nullReturnNoop,
});

type CustomContentType = Context<ModalContextType> & Context<ModalConfirmationContextType>;

const contentWrapperStyle = ({ spacing, breakpoints }: Theme) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 440,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: spacing(5),
  borderRadius: '16px',
  outline: 'none',
  display: 'flex',
  flexDirection: 'column',
  [breakpoints.down('sm')]: {
    minWidth: '90%',
    height: '100%',
    overflowY: 'auto',
  },
});

const createStyles = ({ breakpoints, palette, spacing, tokens: { font } }: Theme) => ({
  '.MuiDialogTitle-root': {
    pt: spacing('xl'),
    position: 'relative',
    [breakpoints.down('sm')]: {
      pt: spacing(10),
      lineHeight: font.lineHeight.micro,
    },

    '.CloseButton': {
      position: 'absolute',
      right: spacing('m'),
      top: spacing('l'),
      color: palette.text.secondary,
    },
  },
});

const PortalConfirmationId = 'confirmationRoot';
const PortalModalId = 'modalRoot';

const Anchor = ({ portalId, context: passedContext }: { portalId: string; context: CustomContentType }) => {
  const { content } = useContext(passedContext);
  const [anchor, setAnchor] = useState<ReactPortal | null>(null);

  useEffect(() => {
    if (document.getElementById(portalId)) {
      setAnchor(ReactDOM.createPortal(content, document.getElementById(portalId) as HTMLElement));
    } else {
      setAnchor(null);
    }
  }, [content, portalId]);

  return anchor;
};
const ConfirmationAnchor = () => (
  <Anchor portalId={PortalConfirmationId} context={ConfirmationContext as CustomContentType} />
);

const ModalAnchor = () => <Anchor portalId={PortalModalId} context={ModalContext as CustomContentType} />;

const SlideUp = forwardRef(function SlideUp({ children, ...props }: SlideProps, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {children}
    </Slide>
  );
});
const FadeIn = forwardRef(function FadeIn({ children, ...props }: FadeProps, ref) {
  return (
    <Fade ref={ref} {...props}>
      {children}
    </Fade>
  );
});

const MobileTransition = memo(SlideUp);
const DesktopTransition = memo(FadeIn);

type ModalOptions = {
  onClose?: () => void;
  withAnchor?: boolean;
  maxWidth?: WidthType;
  modalProps?: any;
  paperProps?: any;
};

const deafultModalOptions: ModalOptions = { onClose: undefined, withAnchor: undefined, maxWidth: undefined };

type ConfirmationsOptions = {
  disableClickAwayHandler?: boolean;
  withAnchor?: boolean;
  hideCloseButton?: boolean;
  onClose?: () => void;
  paperProps?: {
    sx: {
      maxWidth: ({ spacing }: Theme) => string;
    };
  };
};

const deafultConfirmationsOptions: ConfirmationsOptions = {
  disableClickAwayHandler: undefined,
  withAnchor: undefined,
  onClose: undefined,
};

export const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [confirmationContent, setConfirmationContent] = useState<ReactNode | null>();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationOptions, setConfirmationOptions] = useState<ConfirmationsOptions>(deafultConfirmationsOptions);
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOptions, setModalOptions] = useState<ModalOptions>(deafultModalOptions);
  const isMobile = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down('sm'));
  const { sx: sxModal, ...modalProps } = modalOptions.modalProps || {};
  const { sx: sxPaper, ...paperProps } = modalOptions.paperProps || {};
  const [modalContainerStyles, setModalContainerStyles] = useState({});
  const [anchorModalContainerStyles, setAnchorModalContainerStyles] = useState({});

  useEffect(() => {
    if (!modalOpen && modalOptions.onClose) {
      modalOptions.onClose();
    }
  }, [modalOpen, modalOptions]);

  const openConfirmation = useCallback(
    (content: ReactNode, options?: ConfirmationsOptions) => {
      setConfirmationContent(content);
      setConfirmationOpen(true);
      setConfirmationOptions(options ?? {});
    },
    [setConfirmationContent, setConfirmationOpen],
  );

  const closeConfirmation = useCallback(() => {
    setConfirmationOpen(false);
    setConfirmationContent(null);
  }, [setConfirmationOpen, setConfirmationContent]);

  const openModal = useCallback(
    (content: ReactNode, options?: ModalOptions) => {
      setModalContent(content);
      setModalOpen(true);
      setModalOptions(options ?? deafultModalOptions);
    },
    [setModalOptions, setModalContent, setModalOpen],
  );

  const closeModal = useCallback(() => {
    setModalOpen(false);
    setModalContent(null);
  }, [setModalOpen, setModalContent]);

  return (
    <ConfirmationContext.Provider
      value={{ openConfirmation, closeConfirmation, content: confirmationContent, ConfirmationAnchor }}
    >
      <ModalContext.Provider
        value={{
          openModal,
          closeModal,
          content: modalContent,
          ModalAnchor,
          setModalContainerStyles,
          setAnchorModalContainerStyles,
        }}
      >
        {children}

        <Dialog
          open={modalOpen && !!modalContent}
          fullScreen={isMobile}
          onClose={closeModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          TransitionComponent={isMobile ? MobileTransition : DesktopTransition}
          scroll="paper"
          sx={[createStyles, sxModal]}
          maxWidth={modalOptions.maxWidth ?? 'md'}
          fullWidth
          keepMounted
          slots={{ backdrop: Backdrop }}
          {...modalProps}
          PaperProps={{
            sx: [{ alignItems: 'stretch', justifyContent: 'flex-start' }, sxPaper, modalContainerStyles],
            ...paperProps,
          }}
        >
          {modalOptions.withAnchor ? (
            <Box sx={[{ display: 'flex', flexDirection: 'column' }, anchorModalContainerStyles]} id={PortalModalId} />
          ) : (
            modalContent
          )}
        </Dialog>
        <Modal
          open={confirmationOpen && !!confirmationContent}
          keepMounted
          onClose={closeConfirmation}
          aria-labelledby="confirmation-title"
          aria-describedby="confirmation-description"
          data-testid="open-confirmation"
          slots={{ backdrop: Backdrop }}
          slotProps={{
            root: { className: confirmationOptions?.disableClickAwayHandler ? DISABLE_CLICK_AWAY_CLASS : '' },
          }}
        >
          <Box sx={contentWrapperStyle}>
            {confirmationOptions.withAnchor ? <Box id={PortalConfirmationId} /> : confirmationContent}
          </Box>
        </Modal>
      </ModalContext.Provider>
    </ConfirmationContext.Provider>
  );
};
