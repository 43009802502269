const getMimeTypesFromFallbackMapping = (ary = []) =>
  ary.reduce((list, { mimeType }) => (mimeType ? [...list, mimeType] : list), []);

export const ACCEPTED_FILE_FORMATS = {
  OFFSET: '.jpg,.jpeg,.tiff',
  SHUTTERSTOCK: '.jpg,.jpeg,.tiff,.eps,.mov,.mp4',
  CSV: '.csv',
};

export const UPLOADS_VALID_IMAGE_MIME_TYPES = ['image/jpg', 'image/jpeg', 'image/tiff'];
export const UPLOADS_VALID_ILLUSTRATION_MIME_TYPES = ['application/postscript', 'image/x-eps'];

const IMAGE_FALLBACK_JPEG = {
  mimeType: 'image/jpeg',
  extensions: ['.jpg', '.jpeg'],
};

const IMAGE_FALLBACK_PNG = {
  mimeType: 'image/png',
  extensions: ['.png'],
};

const IMAGE_FALLBACK_TIFF = {
  mimeType: 'image/tiff',
  extensions: ['.tiff'],
};

// VIDEO FALLBACK MIME-TYPES mapped to file extension
const VIDEO_FALLBACK_MP4 = {
  mimeType: 'video/mp4',
  extensions: ['.mp4'],
};

const VIDEO_FALLBACK_WEBM = {
  mimeType: 'video/webm',
  extensions: ['.webm'],
};

const VIDEO_FALLBACK_OGG = {
  mimeType: 'video/ogg',
  extensions: ['.ogg', '.ogv', '.ogx', '.ogm', '.spx', '.opus'],
};

const VIDEO_FALLBACK_MKV = {
  mimeType: 'video/x-matroska',
  extensions: ['.mkv'],
};

const VIDEO_FALLBACK_QUICKTIME = {
  mimeType: 'video/quicktime',
  extensions: ['.mov', '.qt'],
};

const VIDEO_FALLBACK_ASF = {
  mimeType: 'video/x-ms-asf',
  extensions: ['.asf'],
};

const VIDEO_FALLBACK_WMV = {
  mimeType: 'video/x-ms-wmv',
  extensions: ['.wmv'],
};

const VIDEO_FALLBACK_AVI = {
  mimeType: 'video/x-msvideo',
  extensions: ['.avi'],
};

const UPLOADS_IMAGE_FALLBACK_MAPPING = [IMAGE_FALLBACK_JPEG, IMAGE_FALLBACK_PNG, IMAGE_FALLBACK_TIFF];

export const UPLOADS_VIDEO_FALLBACK_MAPPING = [
  VIDEO_FALLBACK_MP4,
  VIDEO_FALLBACK_WEBM,
  VIDEO_FALLBACK_OGG,
  VIDEO_FALLBACK_MKV,
  VIDEO_FALLBACK_QUICKTIME,
  VIDEO_FALLBACK_ASF,
  VIDEO_FALLBACK_WMV,
  VIDEO_FALLBACK_AVI,
];

// Leverage the 'mimeTypes' defined in all the VIDEO_FALLBACK cont's
export const UPLOADS_VIDEO_FALLBACK_MIME_TYPES_MAPPING =
  getMimeTypesFromFallbackMapping(UPLOADS_VIDEO_FALLBACK_MAPPING);

export const UPLOADS_VALID_VIDEO_MIME_TYPES = [
  ...UPLOADS_VIDEO_FALLBACK_MIME_TYPES_MAPPING,
  'video/mpeg',
  'video/x-msvideo',
  'video/x-ogm',
];

export const VALID_UPLOAD_MIME_TYPES = [
  ...UPLOADS_VALID_IMAGE_MIME_TYPES,
  ...UPLOADS_VALID_VIDEO_MIME_TYPES,
  ...UPLOADS_VALID_ILLUSTRATION_MIME_TYPES,
];

export const UPLOAD_FALLBACK_MIME_TYPES_MAPPING = [
  ...UPLOADS_VIDEO_FALLBACK_MAPPING,
  ...UPLOADS_IMAGE_FALLBACK_MAPPING,
];

// File uploading
const MEGABYTE = 1024 * 1024;

// File size limits for uploads
export const FILE_SIZE_LIMIT_65MB = 65 * MEGABYTE;
export const FILE_SIZE_LIMIT_50MB = 50 * MEGABYTE;
export const FILE_SIZE_LIMIT_100MB = 100 * MEGABYTE;
export const FILE_SIZE_LIMIT_4GB = 4000 * MEGABYTE;
