export const END_APP_NAME = 'x-end-app-name';
export const END_APP_VERSION = 'x-end-app-version';
export const REQUEST_ID = 'x-request-id';
export const SHUTTERSTOCK_SEARCH_ID = 'x-shutterstock-search-id';
export const ACCEPT = 'Accept';
export const CONTENT_TYPE = 'content-type';
export const HOST = 'host';
export const X_END_USER_COUNTRY = 'x-end-user-country';
export const X_END_USER_VISIT_ID = 'x-end-user-visit-id';
export const X_END_USER_VISITOR_ID = 'x-end-user-visitor-id';
export const AUTHORIZATION = 'Authorization';
export const X_END_USER_ANONYMOUS_ID = 'x-end-user-anonymous-id';
