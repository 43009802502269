import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'next-i18next';

import { LINK_TYPE, NON_C2N_ROUTES, SITE_ROUTES } from '@/constants/global';
import { ICON_SIZE } from '@/constants/icons';
import { useFetchUser } from '@/hooks/useFetchUser';
import { useFormatLink } from '@/hooks/useFormatLink';
import { AllImagesIcon } from '@/icons/AllImagesIcon';
import { DocumentIcon } from '@/icons/DocumentIcon';
import { UploadIcon } from '@/icons/UploadIcon';
import { VideoIcon } from '@/icons/VideoIcon';

import { HelpfulTipBlock } from './HelpfulTipBlock';

const contributorSupportRedirect = `${NON_C2N_ROUTES.CONTRIBUTOR_SUPPORT}/redirect`;

// eslint-disable-next-line jsx-a11y/anchor-is-valid
const Anchor = (props) => <Link href="" target="_blank" {...props} />;

export const AssetUploadHints = ({ ns: NAMESPACE }) => {
  const { t } = useTranslation(NAMESPACE);
  const { isOffsetUser } = useFetchUser();
  const imageSupport = useFormatLink({
    href: contributorSupportRedirect,
    queries: { a: '000006575' },
    linkType: LINK_TYPE.SHUTTERSTOCK_REDIRECT,
  });

  const videoSupport = useFormatLink({
    href: contributorSupportRedirect,
    linkType: LINK_TYPE.SHUTTERSTOCK_REDIRECT,
    queries: { a: '000006587' },
  });

  const ftpsLearnMore = useFormatLink({
    href: contributorSupportRedirect,
    linkType: LINK_TYPE.SHUTTERSTOCK_REDIRECT,
    queries: { a: '000006653' },
  });

  const learnMore = isOffsetUser
    ? 'portfolio.empty.tips_and_requirements.image_learn_more_offset'
    : 'portfolio.empty.tips_and_requirements.image_learn_more';

  return (
    <Container
      maxWidth="xl"
      sx={{ mt: 11, '& a': ({ palette: { link } }) => ({ color: link.main, textDecorationColor: 'inherit' }) }}
      data-testid="tips_and_requirements"
    >
      <Typography variant="bodyStaticSm" fontWeight="bold" textAlign="center" color="text.secondary" mb={2}>
        {t('portfolio.empty.tips_and_requirements.title')}
      </Typography>
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
        <HelpfulTipBlock
          icon={<AllImagesIcon sx={{ fontSize: ICON_SIZE.MEDIUM }} />}
          title={t('portfolio.empty.tips_and_requirements.image')}
          description={
            <Trans
              ns={NAMESPACE}
              i18nKey={learnMore}
              values={{ LINK_LEARN_MORE_IMAGE_FORMAT: imageSupport }}
              components={{
                a: <Anchor data-testid={learnMore} />,
              }}
            />
          }
        />
        {!isOffsetUser && (
          <HelpfulTipBlock
            icon={<VideoIcon sx={{ fontSize: ICON_SIZE.MEDIUM }} />}
            title={t('portfolio.empty.tips_and_requirements.video')}
            description={
              <Trans
                ns={NAMESPACE}
                i18nKey="portfolio.empty.tips_and_requirements.video_learn_more"
                values={{ LINK_LEARN_MORE_VIDEO_FORMAT: videoSupport }}
                components={{ a: <Anchor data-testid="portfolio.empty.tips_and_requirements.video_learn_more" /> }}
              />
            }
          />
        )}
        <HelpfulTipBlock
          icon={<DocumentIcon sx={{ fontSize: ICON_SIZE.MEDIUM }} />}
          title={t('portfolio.empty.tips_and_requirements.permissions_and_releases')}
          description={
            <Anchor
              data-testid="portfolio.empty.tips_and_requirements.permissions_and_releases_learn_more"
              href={SITE_ROUTES.LEGAL_PAGE}
            >
              {t('learn_more_link')}
            </Anchor>
          }
        />
        <HelpfulTipBlock
          icon={<UploadIcon sx={{ fontSize: ICON_SIZE.MEDIUM }} />}
          title={t('upload_step_1_title_v2')}
          description={
            <Trans
              ns={NAMESPACE}
              i18nKey="portfolio.empty.tips_and_requirements.ftps_learn_more"
              values={{ LINK_LEARN_MORE_FTPS: ftpsLearnMore }}
              components={{ a: <Anchor data-testid="portfolio.empty.tips_and_requirements.ftps_learn_more" /> }}
            />
          }
        />
      </Stack>
    </Container>
  );
};
