export const NAMESPACE_COMMON = 'common';
export const NAMESPACE_LOCATIONS = 'locations';

// PAGES
export const NAMESPACE_PAYMENT_HISTORY = 'paymentHistory';
export const NAMESPACE_MY_REFERRALS = 'myReferrals';
export const NAMESPACE_EARNINGS_SUMMARY = 'earnings';
export const NAMESPACE_TAX_CENTER = 'taxCenter';
export const NAMESPACE_PUBLIC_PROFILE = 'publicProfile';
export const NAMESPACE_ACCOUNT_SETTINGS = 'accountSettings';
export const NAMESPACE_ERROR = 'errorPage';
export const NAMESPACE_UNSUPPORTED_BROWSER = 'unsupportedBrowser';
export const NAMESPACE_PORTFOLIO = 'portfolio';
export const NAMESPACE_DASHBOARD = 'dashboard';
export const NAMESPACE_TOP_PERFORMERS = 'topPerformers';
export const NAMESPACE_SIGNUP_WELCOME = 'welcome';
export const NAMESPACE_COLLECTIONS = 'collections';
export const NAMESPACE_RELEASES = 'releases';
export const NAMESPACE_DATA_CATALOG = 'dataCatalog';
export const NAMESPACE_TERMS_OF_SERVICE = 'termsOfService';
export const NAMESPACE_LEGAL = 'legal';
export const NAMESPACE_PAYOUTS = 'payouts';
export const NAMESPACE_LOHP = 'lohp';
export const NAMESPACE_CONTACT = 'contact';
export const NAMESPACE_TRENDS = 'trends';

// COMPONENTS
export const NAMESPACE_USER_DETAILS_DRAWER = 'userDetailsDrawer';

// WORKFLOWS
export const NAMESPACE_UPLOAD_ERRORS = 'uploadErrors';
