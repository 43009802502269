import { PUBLIC_USERS_URL } from '@/constants/api';

const publicProfilePostfix = 'profile';

export const getPublicProfile = (contributorId: number | string) => {
  const url = [PUBLIC_USERS_URL, contributorId.toString(), publicProfilePostfix].join('/');

  return {
    url,
    formattedUrl: url,
  };
};
