import AnalyticsWrapper from '@shutterstock-private/analytics-wrapper';
import Cookies from 'js-cookie';
import { createContext, useContext } from 'react';
/**
 * Helper function that instantiates 'analytics' prop at the beginning of MyApp's
 * component lifecycle on the client side.
 * This prop is passed into the AnalyticsProvider.
 * This way, we avoid instantiating it in the useEffect and causing an extra re-render.
 * @param {Object} obj - an object
 * @param {Object} obj.analytics - public analytics object
 * @returns {Object}
 */
export const getAnalyticsInstance = ({ analytics }) => {
  // Server side or analytics config is empty
  if (typeof window === 'undefined' || typeof analytics === 'undefined') return {};

  const {
    debugMode,
    hightouch: { publicKey },
    GTM: { id: GTMId },
  } = analytics;

  const gtmIdFromCookie = Cookies.get('GTM_ID');
  // Client side
  const analyticsWrapper = new AnalyticsWrapper({
    debugMode,
    hightouch: {
      enabled: true,
      writeKey: publicKey,
      apiHost: 'ht-proxy.analytics.shutterstock.com',
    },
    gtm: {
      enabled: true,
      id: gtmIdFromCookie || GTMId,
    },
  });

  analyticsWrapper.inlineClick = (eventName, options) => {
    const a = eventName.split('.');

    analyticsWrapper.click({
      pageSection: a[0],
      eventLabel: a[1],
      eventValue: options,
    });
  };

  return analyticsWrapper;
};

/**
 * Gets data needed to pass to page view events for analytics tracking.
 *
 * @param {string} environment - Environment analytics is being tracked on
 * @param {obj} router - router
 */

export const getPageData = (environment, router) => ({
  applicationName: 'contributor-web',
  environment: environment || 'dev',
  originalUrl: window.location.href,
  pageLanguage: router.locale,
  referringUrl: document.referrer,
  site: 'contributor',
  pageType: router.pathname,
});

/**
 * Gets user data for analytics
 *
 * @param {obj} - user object
 */
export const getUserData = (user) => {
  if (user?.data?.details?.account?.contributorId) {
    const { contributorId, createTime } = user.data.details.account;

    return {
      id: `${contributorId}`,
      status: 'contributor',
      isActive: 'true',
      createTime,
    };
  }

  return {
    id: '',
    status: 'guest',
    isActive: false,
  };
};

/**
 * Takes analytics instance and initializes it on the client/browser side
 * calls methods from @shutterstock-private/analytics-wrapper AnalyticsWrapper class
 *
 * @param {class} analyticsInstance  - class instance of AnalyticsWrapper
 * @param {object} pageData          - object with data needed to record a page view tracking
 */
export const initializeAnalytics = async (analyticsInstance, publicRuntimeConfig, router, user, visitorInfo) => {
  const {
    ENV,
    analytics: { consentLibraryId },
  } = publicRuntimeConfig;
  const pageData = getPageData(ENV, router);

  analyticsInstance.setInitialData({
    page: pageData,
    user: getUserData(user),
    visit: visitorInfo,
  });

  let consentLibrary;

  // Dynamically import in the '@shutterstock-private/consent-library' when this function is called
  const DynamicConsentLibrary = await import(
    /* webpackChunkName: "ConsentLibrary" */ '@shutterstock-private/consent-library'
  );
  const ConsentLibrary = DynamicConsentLibrary.default;

  if (!window.isConsentLibraryLoading && !window.isConsentLibraryLoaded) {
    consentLibrary = new ConsentLibrary({
      id: consentLibraryId,
      onConsentChanged() {
        window.location.reload();
      },
    });
    try {
      await consentLibrary.load();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(
        'Error loading consent library to ensure that the user has consented to tracking. Analytics data will not be collected.',
        err,
      );
      window.NREUM?.noticeError?.(err);

      return;
    }
  }

  if (!analyticsInstance.isProviderLoadingOrLoaded()) {
    await analyticsInstance.loadProvider();
  }

  analyticsInstance.page({ pageType: pageData.pageType });
};

// Analytics Context
const AnalyticsContext = createContext();

export const AnalyticsProvider = AnalyticsContext.Provider;

export const useAnalyticsContext = () => useContext(AnalyticsContext);

// Analytics PageSection Context
const AnalyticsPageSectionContext = createContext();

export const AnalyticsPageSectionProvider = AnalyticsPageSectionContext.Provider;

export const useAnalyticsPageSectionContext = () => useContext(AnalyticsPageSectionContext);
