import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import { CommonTrackActions } from '@/constants/analytics';
import { LINK_TYPE, MENU_ITEMS, NON_C2N_ROUTES, SITE_ROUTES } from '@/constants/global';
import { AccountSettingsIcon } from '@/icons/AccountSettingsIcon';
import { ArrowRightIcon } from '@/icons/ArrowRightIcon';
import { BookmarkIcon } from '@/icons/BookmarkIcon';
import { CatalogIcon } from '@/icons/CatalogIcon';
import { ChatIcon } from '@/icons/ChatIcon';
import { DataCatalogIcon } from '@/icons/dataCatalogIcon';
import { DataIcon } from '@/icons/DataIcon';
import { DocumentIcon } from '@/icons/DocumentIcon';
import { HelpIcon } from '@/icons/HelpIcon';
import { HistoryIcon } from '@/icons/HistoryIcon';
import { HomeIcon } from '@/icons/HomeIcon';
import { IllustrationsIcon } from '@/icons/IllustrationsIcon';
import { InsightsIcon } from '@/icons/InsightsIcon';
import { PeopleOutlineIcon } from '@/icons/PeopleOutlineIcon';
import { PersonOutlineIcon } from '@/icons/PersonOutlineIcon';
import { PhotosIcon } from '@/icons/PhotosIcon';
import { PortfolioIcon } from '@/icons/PortfolioIcon';
import { PublishIcon } from '@/icons/PublishIcon';
import { SaveToCollectionIcon } from '@/icons/SaveToCollectionIcon';
import { SearchIcon } from '@/icons/SearchIcon';

export const SUBMENU_ITEMS = {
  [MENU_ITEMS.EARNINGS]: [
    MENU_ITEMS.EARNINGS_SUMMARY,
    MENU_ITEMS.MY_REFERRALS,
    MENU_ITEMS.PAYMENT_HISTORY,
    MENU_ITEMS.TAX_CENTER,
  ],
  [MENU_ITEMS.PORTFOLIO]: [
    MENU_ITEMS.SUBMIT_CONTENT,
    MENU_ITEMS.PENDING_CONTENT,
    MENU_ITEMS.REVIEWED_CONTENT,
    MENU_ITEMS.CATALOG_MANAGER,
    MENU_ITEMS.CATALOG_DATA_MANAGER,
    MENU_ITEMS.MANAGE_RELEASES,
    MENU_ITEMS.COLLECTIONS,
    MENU_ITEMS.KEYWORD_SUGGESTIONS,
  ],
  [MENU_ITEMS.INSIGHTS]: [
    MENU_ITEMS.TOP_PERFORMERS,
    MENU_ITEMS.THE_SHOT_LIST,
    MENU_ITEMS.CONTRIBUTOR_BLOG,
    MENU_ITEMS.CONTENT_BRIEFS,
    MENU_ITEMS.TRENDS,
  ],
  [MENU_ITEMS.ACCOUNT]: [MENU_ITEMS.ACCOUNT_SETTINGS, MENU_ITEMS.PUBLIC_PROFILE],
};

export const DESKTOP_SITE_MENU_SECTIONS = {
  UPPER: [MENU_ITEMS.HOME, MENU_ITEMS.EARNINGS, MENU_ITEMS.PORTFOLIO, MENU_ITEMS.INSIGHTS],
  LOWER: [MENU_ITEMS.ACCOUNT, MENU_ITEMS.HELP],
};

export const NON_DESKTOP_SITE_MENU_SECTIONS = {
  UPPER: [MENU_ITEMS.HOME, MENU_ITEMS.EARNINGS, MENU_ITEMS.PORTFOLIO, MENU_ITEMS.INSIGHTS],
  LOWER: [MENU_ITEMS.ACCOUNT_SETTINGS, MENU_ITEMS.PUBLIC_PROFILE, MENU_ITEMS.HELP, MENU_ITEMS.LOG_OUT],
};

// for matching menu item with current path exactly, e.g. 'My referrals' matches with '/referrals'. Used for styling
// (highlighting) the menu icon of the page the current user is on, in the Site Menu.
export const ROUTE_TO_ICON_MAPPING = {
  [SITE_ROUTES.HOME_PAGE]: MENU_ITEMS.HOME,
  [SITE_ROUTES.EARNINGS_PAGE]: MENU_ITEMS.EARNINGS_SUMMARY,
  [SITE_ROUTES.MY_REFERRALS]: MENU_ITEMS.MY_REFERRALS,
  [SITE_ROUTES.PAYMENT_HISTORY]: MENU_ITEMS.PAYMENT_HISTORY,
  [SITE_ROUTES.TAX_CENTER]: MENU_ITEMS.TAX_CENTER,
  [SITE_ROUTES.PORTFOLIO_PAGE]: MENU_ITEMS.PORTFOLIO,
  [SITE_ROUTES.COLLECTIONS]: MENU_ITEMS.PORTFOLIO,
  [SITE_ROUTES.INSIGHTS_PAGE]: MENU_ITEMS.INSIGHTS,
  [SITE_ROUTES.PUBLIC_PROFILE_PAGE]: MENU_ITEMS.PUBLIC_PROFILE,
  [SITE_ROUTES.ACCOUNT_SETTINGS_PAGE]: MENU_ITEMS.ACCOUNT_SETTINGS,
};

// for matching route with the parent menu entry, e.g. 'My referrals', 'Tax center', 'Payment history' are all
// under the 'Earnings' heading in the Site Menu, so '/referrals', '/legal/taxes', and '/payments' all match with 'Earnings'.
// Used for styling (highlighting) the menu icon of the page the current user is on, in the Site Menu.
export const ROUTE_TO_PARENT_ICON_MAPPING = {
  [SITE_ROUTES.EARNINGS_PAGE]: MENU_ITEMS.EARNINGS,
  [SITE_ROUTES.MY_REFERRALS]: MENU_ITEMS.EARNINGS,
  [SITE_ROUTES.PAYMENT_HISTORY]: MENU_ITEMS.EARNINGS,
  [SITE_ROUTES.TAX_CENTER]: MENU_ITEMS.EARNINGS,
  [SITE_ROUTES.COLLECTIONS_ROUTE]: MENU_ITEMS.PORTFOLIO,
  [SITE_ROUTES.INSIGHTS_PAGE]: MENU_ITEMS.INSIGHTS,
  [SITE_ROUTES.PUBLIC_PROFILE_PAGE]: MENU_ITEMS.ACCOUNT,
  [SITE_ROUTES.ACCOUNT_SETTINGS_PAGE]: MENU_ITEMS.ACCOUNT,
  [SITE_ROUTES.CONTACT_PAGE]: MENU_ITEMS.HELP,
};

export const MENU_ITEM_INFO = {
  [MENU_ITEMS.HOME]: {
    href: SITE_ROUTES.HOME_PAGE,
    iconTextKey: 'home',
    icon: HomeIcon,
  },
  [MENU_ITEMS.EARNINGS]: {
    // Entry on top-level Site Menu is "Earnings", entry inside drawer is "Earnings Summary"
    href: SITE_ROUTES.EARNINGS_PAGE,
    iconTextKey: 'contributor_header_earnings',
    icon: MonetizationOnOutlinedIcon,
  },
  [MENU_ITEMS.EARNINGS_SUMMARY]: {
    href: SITE_ROUTES.EARNINGS_PAGE,
    iconTextKey: 'contributor_lihp_react.earnings_summary.title',
    icon: MonetizationOnOutlinedIcon,
  },
  [MENU_ITEMS.MY_REFERRALS]: {
    href: SITE_ROUTES.MY_REFERRALS,
    iconTextKey: 'contributor_header_earnings_my_referrals',
    icon: PeopleOutlineIcon,
  },
  [MENU_ITEMS.PAYMENT_HISTORY]: {
    href: SITE_ROUTES.PAYMENT_HISTORY,
    iconTextKey: 'contributor_header_payment_history',
    icon: HistoryOutlinedIcon,
  },
  [MENU_ITEMS.TAX_CENTER]: {
    href: SITE_ROUTES.TAX_CENTER,
    iconTextKey: 'contributor_legal_center_tax_center',
    icon: ListAltOutlinedIcon,
  },
  [MENU_ITEMS.MORE]: {
    href: SITE_ROUTES.ACCOUNT_SETTINGS_PAGE,
    iconTextKey: 'contributor_header_user_menu_account_settings',
    icon: PersonOutlineIcon,
  },
  [MENU_ITEMS.PUBLIC_PROFILE]: {
    href: SITE_ROUTES.PUBLIC_PROFILE_PAGE,
    iconTextKey: 'contributor_header_user_menu_public_profile',
    icon: PersonOutlineIcon,
    defaultString: 'Public profile',
  },
  [MENU_ITEMS.HELP]: {
    href: SITE_ROUTES.CONTACT_PAGE,
    iconTextKey: 'help',
    icon: HelpIcon,
  },
  [MENU_ITEMS.ACCOUNT]: {
    href: SITE_ROUTES.ACCOUNT_SETTINGS_PAGE,
    iconTextKey: 'cu_account',
    icon: PersonOutlineIcon,
  },
  [MENU_ITEMS.ACCOUNT_SETTINGS]: {
    href: SITE_ROUTES.ACCOUNT_SETTINGS_PAGE,
    iconTextKey: 'contributor_header_user_menu_account_settings',
    icon: AccountSettingsIcon,
  },
  [MENU_ITEMS.PORTFOLIO]: {
    href: '',
    iconTextKey: 'contributor_header_portfolio',
    icon: PortfolioIcon,
  },
  [MENU_ITEMS.SUBMIT_CONTENT]: {
    href: SITE_ROUTES.PORTFOLIO_NOT_SUBMITTED_PHOTO,
    iconTextKey: 'contributor_header_portfolio_submit_content_sentence_case',
    icon: PublishIcon,
    defaultString: 'Submit content',
  },
  [MENU_ITEMS.PENDING_CONTENT]: {
    href: SITE_ROUTES.PORTFOLIO_PENDING_PHOTO,
    iconTextKey: 'contributor_header_portfolio_pending_content_sentence_case',
    icon: HistoryIcon,
    defaultString: 'Pending content',
  },
  [MENU_ITEMS.REVIEWED_CONTENT]: {
    href: SITE_ROUTES.PORTFOLIO_RECENTLY_REVIEWED_PHOTO,
    iconTextKey: 'contributor_header_portfolio_reviewed_content_sentence_case',
    icon: ChatIcon,
    defaultString: 'Reviewed content',
  },
  [MENU_ITEMS.CATALOG_MANAGER]: {
    href: SITE_ROUTES.PORTFOLIO_PUBLISHED_PHOTO,
    iconTextKey: 'contributor_header_portfolio_catalog_manager_sentence_case',
    icon: CatalogIcon,
    defaultString: 'Catalog manager',
  },
  [MENU_ITEMS.CATALOG_DATA_MANAGER]: {
    href: SITE_ROUTES.DATA_CATALOG_PHOTO,
    iconTextKey: 'data_catalog_title',
    icon: DataCatalogIcon,
    defaultString: 'Data Catalog',
    label: 'data_catalog_label',
  },
  [MENU_ITEMS.MANAGE_RELEASES]: {
    href: SITE_ROUTES.RELEASES,
    iconTextKey: 'contributor_header_portfolio_manage_releases_sentence_case',
    icon: IllustrationsIcon,
    defaultString: 'Manage releases',
  },
  [MENU_ITEMS.COLLECTIONS]: {
    href: SITE_ROUTES.COLLECTIONS,
    iconTextKey: 'collections',
    icon: SaveToCollectionIcon,
    defaultString: 'Collections',
  },
  [MENU_ITEMS.KEYWORD_SUGGESTIONS]: {
    linkType: LINK_TYPE.CCWEB_REDIRECT,
    href: NON_C2N_ROUTES.KEYWORD_TOOL,
    iconTextKey: 'contributor_header_portfolio_keyword_suggestions_sentence_case',
    icon: SearchIcon,
    defaultString: 'Keyword suggestions',
  },
  [MENU_ITEMS.INSIGHTS]: {
    href: '(no corresponding page to link to)',
    iconTextKey: 'contributor_header_insights',
    icon: InsightsIcon,
  },
  [MENU_ITEMS.TOP_PERFORMERS]: {
    href: SITE_ROUTES.TOP_PERFORMERS,
    iconTextKey: 'contributor_header_insights_top_performers_sentence_case',
    icon: DataIcon,
    defaultString: 'Top performers',
  },
  [MENU_ITEMS.THE_SHOT_LIST]: {
    linkType: LINK_TYPE.SHUTTERSTOCK_NEXT_PUBLIC,
    href: NON_C2N_ROUTES.THE_SHOT_LIST,
    iconTextKey: 'contributor_header_insights_the_shot_list_sentence_case',
    icon: PhotosIcon,
    defaultString: 'The shot list',
  },
  [MENU_ITEMS.CONTRIBUTOR_BLOG]: {
    linkType: LINK_TYPE.CONTRIBUTOR_BLOG, // 'CONTRIBUTOR_BLOG' linkType determines actual link here, not href
    iconTextKey: 'contributor_header_insights_contributor_blog_sentence_case',
    icon: BookmarkIcon,
    defaultString: 'Contributor blog',
  },
  [MENU_ITEMS.LOG_OUT]: {
    href: SITE_ROUTES.LOGOUT,
    iconTextKey: 'log_out_sentence_case',
    icon: ArrowRightIcon,
    defaultString: 'Log out',
    trackAction: CommonTrackActions.logout,
  },
  [MENU_ITEMS.CONTENT_BRIEFS]: {
    linkType: LINK_TYPE.SHUTTERSTOCK_NEXT_PUBLIC,
    href: NON_C2N_ROUTES.CONTENT_BRIEFS,
    iconTextKey: 'content_briefs.title',
    icon: DocumentIcon,
    defaultString: 'Content Briefs',
  },
  [MENU_ITEMS.TRENDS]: {
    href: SITE_ROUTES.TRENDS,
    iconTextKey: 'trends.title',
    icon: TrendingUpIcon,
    defaultString: 'Trending content',
    label: 'new',
  },
};
