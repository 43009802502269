import Box from '@mui/material/Box';
import { DragEvent } from 'react';

import { SiteContent } from '@/components/SiteContent';
import { SiteFooter } from '@/components/SiteFooter';
import { SiteHeader } from '@/components/SiteHeader';
import { SiteMenu } from '@/components/SiteMenu';
import type { SxStyles } from '@/types/styles';

const classes = {
  fullPage: { position: 'relative' },
  defaultPageSize: {
    display: 'flex',
    minHeight: '100vh',
  },
  contentAndFooterWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  siteContent: {
    p: { xs: 2, md: 3 },
  },
};

const dropBlocked = (e: DragEvent<HTMLDivElement>) => {
  e.dataTransfer.dropEffect = 'none';
  e.dataTransfer.effectAllowed = 'none';
  e.preventDefault();

  return false;
};

export type PrimaryLayoutProps = {
  children: React.ReactNode;
};

type BasicLayoutProps = {
  children: React.ReactNode;
  layoutStyles: Record<string, SxStyles> | SxStyles;
  disableGutters?: boolean;
};

export const PrimaryLayout = ({ children }: PrimaryLayoutProps) => (
  <BasicLayout layoutStyles={classes}>{children}</BasicLayout>
);

export const PrimaryLayoutFullWidth = ({ children }: PrimaryLayoutProps) => (
  <BasicLayout layoutStyles={{ ...classes, siteContent: { p: 0, minWidth: '100%' } }} disableGutters>
    {children}
  </BasicLayout>
);

const BasicLayout = ({ children, layoutStyles, disableGutters }: BasicLayoutProps) => (
  <Box sx={layoutStyles.fullPage} onDragOverCapture={dropBlocked} onDropCapture={dropBlocked}>
    <SiteHeader />
    <Box id="site-menu-and-page-content" sx={layoutStyles.defaultPageSize}>
      {/*
      SiteMenu extends alongside the Content and footer container when the content gets too large
      i.e. Footer will be pushed to the bottom and the ContentMenu will match the depth
  */}
      <SiteMenu />
      <Box id="content-and-footer" sx={layoutStyles.contentAndFooterWrapper}>
        <SiteContent siteContentStyle={layoutStyles.siteContent} disableGutters={disableGutters}>
          {children}
        </SiteContent>
        <SiteFooter />
      </Box>
    </Box>
  </Box>
);
