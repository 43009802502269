/**
 *
 */
export const ENVS = {
  dev: 'dev',
  qa: 'qa',
  production: 'production',
} as const;

/**
 *
 */
export const ENV_KEYS = {
  sdkKey: 'switchr-sdk-key',
  sdkEnv: 'NODE_CONFIG_ENV',
};
