import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Trans, useTranslation } from 'next-i18next';
import { useMemo, useRef } from 'react';

import { AssetUploadHints } from '@/components/AssetUploadHints/AssetUploadHints';
import { Link } from '@/components/Link';
import { useHandleUpload } from '@/components/UploadSnackbar/useHandleUpload';
import { Button } from '@/components/WithTracking/Button';
import { CommonTrackActions, PortfolioPageSections } from '@/constants/analytics';
import { ACCEPTED_FILE_FORMATS } from '@/constants/files';
import { LINK_TYPE, NON_C2N_ROUTES } from '@/constants/global';
import { PortfolioTabs } from '@/constants/media';
import { useFetchUploadingOk } from '@/hooks/useFetchUploadingOk';
import { useFetchUser } from '@/hooks/useFetchUser';
import { useFormatLink } from '@/hooks/useFormatLink';
import { useUploadingDisabledNotification } from '@/hooks/useUploadingDisabledNotification';
import { UploadIcon } from '@/icons/UploadIcon';
import { PageSection } from '@/lib/analytics/react-data-tracking';
import { NAMESPACE_COMMON } from '@/translations/namespaces';

import { uploadIconStyle } from './styles';

const contributorSupportRedirect = `${NON_C2N_ROUTES.CONTRIBUTOR_SUPPORT}/redirect`;

// eslint-disable-next-line jsx-a11y/anchor-is-valid
const Anchor = (props) => <Link href="" target="_blank" {...props} />;

// Ignoring coverage for the following component as it will be used internally by `Trans` component.
// istanbul ignore next
const Message = ({ children }) => (
  <Typography component="p" color="text.secondary" variant="bodyStaticMd" textAlign="center">
    {children}
  </Typography>
);

const MAX_FILE_COUNT = 100;

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  containerWithHintsWrapper: {
    width: '100%',
  },
  closeButton: ({ spacing }) => ({
    position: 'absolute',
    right: spacing(3),
    top: spacing(3),
  }),
  errorState: ({ spacing, palette: { border } }) => ({
    border: `1px solid ${border.color1}`,
    height: spacing(24),
    borderRadius: spacing(0.375),
    margin: spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
  uploadButton: {
    padding: ({ spacing }) => spacing('xs', 'xxl'),
    margin: '.5em 0',
  },
};

// used in @/hooks/useUploader.jsx
export const EmptyState = ({ onMaxUploadLimit, failedUploadHandler, successfulUploadHandler }) => {
  const { t } = useTranslation(NAMESPACE_COMMON);
  const { isOffsetUser } = useFetchUser();

  const isMobileOrTablet = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  const { handleOnChange: handleOnUploadChange } = useHandleUpload({
    failedUploadHandler,
    successfulUploadHandler,
  });
  const fileInput = useRef();
  const { isLoading: isUserLoading, uploadingOk: isUploadingAllowed = false } = useFetchUploadingOk();
  const uploadDisabledNotification = useUploadingDisabledNotification();

  const handleOnChange = (e) => {
    if (!isUploadingAllowed) {
      uploadDisabledNotification();

      return;
    }

    handleOnUploadChange(e?.target?.files);
    onMaxUploadLimit(e?.target?.files.length > MAX_FILE_COUNT || !e?.target?.files.length);
  };

  const handleOnClick = () => {
    if (!isUploadingAllowed) {
      uploadDisabledNotification();

      return;
    }

    fileInput.current.click();
  };

  const bodyTextKey = useMemo(
    () => (isOffsetUser ? 'portfolio.empty.body_offset' : 'portfolio.empty.body'),
    [isOffsetUser],
  );

  const imageSupport = useFormatLink({
    href: contributorSupportRedirect,
    queries: { a: '000006575' },
    linkType: LINK_TYPE.SHUTTERSTOCK_REDIRECT,
  });

  const videoSupport = useFormatLink({
    href: contributorSupportRedirect,
    linkType: LINK_TYPE.SHUTTERSTOCK_REDIRECT,
    queries: { a: '000006587' },
  });

  return (
    <PageSection value={PortfolioPageSections.emptyState}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.containerWithHintsWrapper}>
          <Container maxWidth="sm">
            <Stack justifyContent="center" alignItems="center">
              <UploadIcon sx={uploadIconStyle} />
              <Typography textAlign="center" variant="h4" mt={3} mb={2}>
                {t('portfolio.empty.title')}
              </Typography>
              <Trans
                ns={NAMESPACE_COMMON}
                i18nKey={bodyTextKey}
                values={{
                  IMAGE_SUPPORT_LINK: imageSupport,
                  VIDEO_SUPPORT_LINK: videoSupport,
                }}
                components={{ a: <Anchor data-testid={bodyTextKey} /> }}
                parent={Message}
              />
              <Box my={2}>
                {isUserLoading ? (
                  <Skeleton>
                    <Button variant="secondary" startIcon={<UploadIcon />}>
                      {t('portfolio.empty.upload_assets.button_text')}
                    </Button>
                  </Skeleton>
                ) : (
                  <Button
                    variant="secondary"
                    startIcon={<UploadIcon />}
                    data-testid="uploadButton"
                    onClick={handleOnClick}
                    trackAction={CommonTrackActions.upload}
                  >
                    {t('portfolio.empty.upload_assets.button_text')}
                  </Button>
                )}

                <input
                  ref={fileInput}
                  accept={isOffsetUser ? ACCEPTED_FILE_FORMATS.OFFSET : ACCEPTED_FILE_FORMATS.SHUTTERSTOCK}
                  type="file"
                  hidden
                  onChange={(e) => handleOnChange(e)}
                  multiple
                />
              </Box>
              <Typography component="p" variant="body2" color="text.secondary">
                {t('portfolio.empty.upload_assets.drag_and_drop')}
              </Typography>
            </Stack>
          </Container>
          {!isMobileOrTablet && <AssetUploadHints t={t} ns={NAMESPACE_COMMON} />}
        </Box>
      </Box>
    </PageSection>
  );
};

export const DynamicEmptyState = ({ tabType, displayError }) => {
  let title;
  let description;
  let button = 'content_editor_digital_upload';
  const { uploadingOk: isUploadingAllowed = false, isLoading: isUserLoading } = useFetchUploadingOk();
  const uploadDisabledNotification = useUploadingDisabledNotification();

  switch (tabType) {
    case PortfolioTabs.NotSubmitted:
    case PortfolioTabs.Pending:
    case PortfolioTabs.Published:
      title = 'portfolio.empty.assets_empty_title';
      description = 'portfolio.empty.assets_empty_description';
      break;

    case PortfolioTabs.RecentlyReviewed:
      title = 'portfolio.empty.recently_reviewed.assets_empty_title';
      description = 'portfolio.empty.assets_empty_description';
      break;

    case PortfolioTabs.TopPerformers:
      title = 'assets_empty_title';
      description = 'assets_empty_description';
      button = 'portfolio.empty.upload_assets.button_text';
      break;
    default:
  }

  const { t } = useTranslation(NAMESPACE_COMMON);

  const { isOffsetUser } = useFetchUser();
  const { handleOnChange: handleOnUploadChange } = useHandleUpload();
  const fileInput = useRef();

  const handleOnClick = () => {
    if (!isUploadingAllowed) {
      uploadDisabledNotification();

      return;
    }

    fileInput.current.click();
  };

  const handleOnChange = (e) => {
    if (!isUploadingAllowed) {
      uploadDisabledNotification();

      return;
    }

    handleOnUploadChange(e?.target?.files);

    if (e?.target?.files.length > MAX_FILE_COUNT || !e?.target?.files.length) {
      displayError?.(t('upload_limit_notification'));
    }
  };

  return (
    <PageSection value={PortfolioPageSections.dynamicEmptyState}>
      <Grid item xs={12}>
        <Box sx={styles.errorState}>
          <Typography component="p" variant="bodyStaticLg" fontWeight="bold">
            {t(title)}
          </Typography>
          <Trans ns={NAMESPACE_COMMON} i18nKey={description} parent={Message} />
          {isUserLoading ? (
            <Skeleton>
              <Button
                startIcon={<UploadIcon />}
                sx={styles.uploadButton}
                data-testid="uploadButton"
                trackAction={CommonTrackActions.upload}
              >
                {t(button)}
              </Button>
            </Skeleton>
          ) : (
            <Button
              variant="secondary"
              startIcon={<UploadIcon />}
              onClick={handleOnClick}
              sx={styles.uploadButton}
              data-testid="uploadButton"
              trackAction={CommonTrackActions.upload}
            >
              {t(button)}
            </Button>
          )}

          <input
            ref={fileInput}
            accept={isOffsetUser ? ACCEPTED_FILE_FORMATS.OFFSET : ACCEPTED_FILE_FORMATS.SHUTTERSTOCK}
            type="file"
            hidden
            onChange={handleOnChange}
            multiple
          />
        </Box>
      </Grid>
    </PageSection>
  );
};
