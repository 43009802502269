import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Trans } from 'next-i18next';
import { useEffect, useRef } from 'react';

import { Button } from '@/components/WithTracking/Button';
import { PortfolioPageSections, PortfolioTrackActions } from '@/constants/analytics';
import { PageSection } from '@/lib/analytics/react-data-tracking';
import { useBannerContext } from '@/providers/Banner/BannerProvider';

const MOBILE_BANNER_MIN_HEIGHT = 90;
const DESKTOP_BANNER_MIN_HEIGHT = 60;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = {
  root: ({ breakpoints, palette }: Theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center' as const,
    backgroundColor: '#26BD7E',
    color: palette.white.main,
    minHeight: `${DESKTOP_BANNER_MIN_HEIGHT}px`,
    position: 'relative' as const,
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      minHeight: `${MOBILE_BANNER_MIN_HEIGHT}px`,
    },
  }),
  button: ({ palette }: Theme) => ({
    margin: 1,
    minWidth: 25,
    borderColor: palette.white.main,
    color: palette.white.main,
    padding: '0 20px',
    '&:hover': {
      borderColor: palette.white.main,
    },
  }),
  text: {
    padding: 0.5,
  },
  link: ({ palette }: Theme) => ({
    color: palette.white.main,
    textDecoration: 'underline',
    '&:hover': {
      borderColor: palette.white.main,
    },
  }),
};

export const Banner = () => {
  const { updateContainerRef, enabled } = useBannerContext();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    updateContainerRef(containerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current, updateContainerRef]);

  if (!enabled) {
    return null;
  }

  return (
    <PageSection value={PortfolioPageSections.banner}>
      <Box sx={styles.root} ref={containerRef}>
        <Typography sx={styles.text} color="common.white" variant="body2">
          <Trans i18nKey="some_text" />
        </Typography>
        <Button variant="secondary" target="_blank" sx={styles.button} trackAction={PortfolioTrackActions.feedback}>
          <Trans i18nKey="cta_text" />
        </Button>
      </Box>
    </PageSection>
  );
};
