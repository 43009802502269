import type { Request, Response } from 'express';
import requestIp from 'request-ip';

import { getSwitchrInstance } from '@/lib/switchr/server/getSwitchr';

// Get the type information from the client's type
type StatsigClient = ReturnType<typeof getSwitchrInstance>['getVendorClient'] extends () => infer R ? R : never;
type StatsigUser = Parameters<StatsigClient['checkGate']>[0];

export type StatsigServerProps = {
  user: ReturnType<typeof getStatsigUser>;
  data: string;
  key: string;
};

export const getStatsigUser = ({
  req,
  res,
  router,
}: {
  req: Request;
  res: Response;
  router: { pathname: string; asPath: string };
}): StatsigUser => {
  // Don't want to allow URL or cookie overriding in prod to prevent any malicious usage.
  const isLowerEnv = process.env.NODE_CONFIG_ENV !== 'production';
  const overrideId = isLowerEnv && typeof req.query?.override_id === 'string' ? req.query?.override_id : undefined;
  const testE2e = isLowerEnv && req.cookies?.switchr_e2e ? req.cookies.switchr_e2e : undefined;
  const browserLocale = req.acceptsLanguages()[0];

  const fullUrl = new URL(`${req.protocol}://${req.get('host')}${req.originalUrl}`);

  const user: StatsigUser = {
    locale: browserLocale,
    country: res.locals?.region,
    userAgent: req.headers['user-agent'],
    email: req.user?.email,
    appVersion: req.cookies?.n_v,
    ip: requestIp.getClientIp(req) || undefined,
    custom: {
      brand: 'shutterstock',
      referer: req.headers.referer,
      url: fullUrl.href,
      pageUrl: router.asPath,
      isLoggedIn: !!req.user?.id,
      pageLanguage: res.locals?.locale,
      site: 'contributor',
      pageType: router.pathname,
      // subPage: undefined, // subPage not used in contributorWeb it seems like
      // 'test-e2e' value watched in custom segment used in experiments conditional override
      // to force experiments to control for automated testing
      // https://console.statsig.com/2FkSXPTvtQJC4nZkAA10eg/segments/segment%3Atest-e2e
      ...(testE2e ? { 'test-e2e': testE2e } : {}),
    },
    customIDs: {
      stableID: res.locals?.anonymousId,
      anonymous_id: res.locals?.anonymousId,
      accounts_id: req.user?.id ? `${req.user.id}` : '',
      ...(overrideId ? { override_id: overrideId } : {}),
    },
  };

  return user;
};

export function getStatsigServerProps({
  req,
  res,
  router,
}: {
  req: Request;
  res: Response;
  router: { pathname: string; asPath: string };
}) {
  // This is a public key on the client, so it is fine to be hardcoded
  const key = 'client-DMQRdjctMd16UjwTJQ2PNXd9CwMiaiFFaD2aAJ5kULv';

  const user = getStatsigUser({ req, res, router });

  res.locals.statsigUser = user;
  const Statsig = getSwitchrInstance().getVendorClient();

  const values = Statsig.getClientInitializeResponse(user, key, {
    hash: 'djb2', // 🔥 'djb2' is required. By default this would be 'sha256'.
  });

  const data = JSON.stringify(values);

  return {
    data,
    user,
    key,
  };
}

/**
 * Check a gate, should only be used in getServerSideProps as it relies on the statsigUser object
 * being set on res.locals in _app.getInitialProps
 * @param gate - The name of the gate to check
 * @param options - An object with a res property, used to get the statsigUser object
 * @returns The value of the gate
 */
export const checkGate = (gate: string, { res }: { res: Response }) => {
  const Statsig = getSwitchrInstance().getVendorClient();

  return Statsig.checkGate(res.locals.statsigUser, gate);
};

/**
 * Get an experiment, records an impression, should only be used in getServerSideProps as it relies on the statsigUser object
 * being set on res.locals in _app.getInitialProps
 * @param experiment - The name of the gate to check
 * @param options - An object with a res property, used to get the statsigUser object
 * @returns The value of the gate
 */
export const getExperiment = (experiment: string, { res }: { res: Response }) => {
  const Statsig = getSwitchrInstance().getVendorClient();

  return Statsig.getExperiment(res.locals.statsigUser, experiment);
};
