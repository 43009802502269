import { Switchr, type SwitchrOptions } from '@sstk/switchr-node';

import { getSDKEnvArgs } from './utils';

export type NextWebSwitchr = Pick<Switchr, 'initialize' | 'shutdown'>;

export const getSwitchrInstance = () => {
  if (global.Switchr === undefined) {
    throw new Error('Switchr has not been initialized');
  }

  return global.Switchr;
};

export const getSwitchr = ({
  onError,
  onInit,
  statsigOptions,
}: {
  onError: (x: Error) => void;
  onInit: () => void;
  statsigOptions?: SwitchrOptions['statsigOptions'];
}): NextWebSwitchr => {
  const envArgs = getSDKEnvArgs();

  return {
    initialize: async () => {
      if (global.Switchr) {
        // eslint-disable-next-line no-console
        console.error('Switchr already initialized');

        return null;
      }

      onInit();

      const switchr = new Switchr(envArgs.sdkKey, {
        env: envArgs.env,
        onError,
        statsigOptions,
      });
      const initDetails = await switchr.initialize();

      global.Switchr = switchr;

      return initDetails;
    },
    shutdown: () => global.Switchr?.shutdown(),
  };
};
