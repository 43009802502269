import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import { Link } from '@/components/Link';
import { ListItemButton } from '@/components/WithTracking/ListItemButton';
import { MENU_ITEMS } from '@/constants/global';
import { useFormatLink } from '@/hooks/useFormatLink';
import { usePageFormState } from '@/hooks/usePageFormState';
import { useUnsavedDialog } from '@/hooks/useUnsavedDialog';
import { ExpandLessIcon } from '@/icons/ExpandLessIcon';
import { ExpandMoreIcon } from '@/icons/ExpandMoreIcon';
import { NAMESPACE_COMMON } from '@/translations/namespaces';

import { MENU_ITEM_INFO } from '../constants';
import { itemHasSubMenu, itemShouldHighlight } from '../helpers';
import { useSubMenuDrawer } from './hooks';

const createStyles = ({ spacing, tokens: { font }, palette, breakpoints }, isMainMenuItem) => ({
  listItem: {
    padding: 0,
  },
  listItemIcon: {
    minWidth: `calc(${spacing('l')} + ${spacing('xs')})`,
    color: palette.text.primary,
  },
  subMenuButton: {
    ml: spacing('m'),
    [breakpoints.down('md')]: {
      px: spacing('s'),
      margin: 0,
    },
  },
  subMenuHeading: {
    '& .MuiTypography-root': {
      textDecoration: 'none',
      overflowWrap: 'anywhere',
      [breakpoints.down('md')]: {
        color: !isMainMenuItem ? palette.darkGrey.main : palette.primary.dark,
        fontWeight: isMainMenuItem && font.weight.bold,
      },
    },
  },
  subMenuSpacing: { width: spacing('xxl') },
  chip: {
    ml: 6,
    borderRadius: spacing(0, 'xxs', 'xs', 'xxs'),
    maxHeight: spacing(2.3),
    marginLeft: spacing(1.5),
    opacity: 1,
    transition: '0.3s',
    '&:hover': {
      opacity: 0.8,
      transform: 'scale(1.1)',
    },
  },
});

export const callAll = (array) => array.forEach((func) => func());

export const SubMenuItem = ({
  open = false,
  iconType,
  mainMenuItem = false,
  functionHandlerList = [],
  noClick = false,
  isOffsetUser = false,
}) => {
  const {
    href,
    linkType,
    iconTextKey,
    icon: SubMenuItemIcon,
    defaultString,
    label,
    trackAction = undefined,
  } = MENU_ITEM_INFO[iconType];

  const { t } = useTranslation(NAMESPACE_COMMON);
  const { asPath } = useRouter();
  const { closeDrawer } = useSubMenuDrawer();
  const isNonDesktopView = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isHighlighted = itemShouldHighlight(iconType, asPath);
  const formattedLink = useFormatLink({ href, linkType });
  const isMainMenuItem = mainMenuItem || itemHasSubMenu(iconType);
  const { pageHasDirtyForm } = usePageFormState();
  const { showCustomUnsavedDialog } = useUnsavedDialog(t('unsaved_changes'));
  const localFunctionHanlders = !itemHasSubMenu(iconType) ? [...functionHandlerList, closeDrawer] : functionHandlerList;

  // On mobile/tablet, prevent icons from being displayed in submenu that is part of a collapsed items list
  const shouldShowIcon = !isNonDesktopView || isMainMenuItem;

  const styles = createStyles(useTheme(), isMainMenuItem);

  const showDataCatalog = !(isOffsetUser && iconType === 'CatalogDataManager');

  const handleClick = (event) => {
    if (pageHasDirtyForm) {
      event.preventDefault();
      showCustomUnsavedDialog(formattedLink);
    } else if (!noClick) {
      return formattedLink;
    }

    return null;
  };

  return (
    <ListItem sx={styles.listItem} onClick={() => callAll(localFunctionHanlders)}>
      {showDataCatalog ? (
        <ListItemButton
          component={noClick ? 'button' : Link}
          href={noClick ? null : formattedLink}
          onClick={handleClick}
          data-testid={iconTextKey}
          sx={styles.subMenuButton}
          selected={isHighlighted}
          tabIndex={-1}
          trackAction={trackAction || null}
        >
          <>
            <ListItemIcon sx={styles.listItemIcon}>
              {shouldShowIcon && <SubMenuItemIcon fontSize="medium" />}
            </ListItemIcon>
            <ListItemText sx={styles.subMenuHeading} variant="bodyStaticSm">
              {t(iconTextKey, defaultString)}
              {[MENU_ITEMS.TRENDS, MENU_ITEMS.CATALOG_DATA_MANAGE].indexOf(iconType) !== -1 && (
                <Chip label={t(label)} sx={styles.chip} color="info" fontSize="10" />
              )}
            </ListItemText>

            {itemHasSubMenu(iconType) &&
              (open ? (
                <ExpandLessIcon data-testid="ExpandLessIcon" />
              ) : (
                <ExpandMoreIcon data-testid="ExpandMoreIcon" />
              ))}
          </>
        </ListItemButton>
      ) : null}
    </ListItem>
  );
};

SubMenuItem.propTypes = {
  iconType: PropTypes.string.isRequired,
  open: PropTypes.bool,
  mainMenuItem: PropTypes.bool,
  functionHandlerList: PropTypes.arrayOf(PropTypes.func),
  noClick: PropTypes.bool,
  isOffsetUser: PropTypes.bool,
};
