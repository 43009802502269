import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import { NAMESPACE_COMMON } from '@/translations/namespaces';

const createStyles = ({ tokens: { color, font }, spacing, breakpoints }: Theme) => ({
  fontSize: font.size.xs,
  color: color['white-70'],
  marginTop: spacing('xs'),
  gridArea: 'copyright-text',
  [breakpoints.down('md')]: {
    padding: `${spacing('xs')} 0`,
    fontSize: font.size.xs,
    margin: 'auto',
  },
});

export const Copyright = () => {
  const { t } = useTranslation(NAMESPACE_COMMON);

  return (
    <>
      <Typography color="textSecondary" variant="bodyStaticXs" sx={createStyles}>
        {t('contributor_footer_copyright', { CURRENT_YEAR: new Date().getFullYear() })}
      </Typography>
    </>
  );
};
