import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Link } from '@/components/Link';
import { MENU_ITEM_INFO } from '@/components/SiteMenu/constants';
import { useFormatLink } from '@/hooks/useFormatLink';
import { NAMESPACE_COMMON } from '@/translations/namespaces';

import { itemHasSubMenu, itemShouldHighlight } from './helpers';
import { MenuButton } from './MenuButton';
import { useSubMenuDrawer } from './SubMenuDrawer/hooks';

const createStyles = ({ palette, spacing, tokens: { font }, breakpoints }, isHighlighted) => ({
  MenuItemWrapper: {
    '&:hover': { cursor: 'pointer' },
    '*': { color: isHighlighted ? palette.primary.main : palette.text.secondary },
    '&:hover *': { color: palette.primary.main },
    width: '100%',
    [breakpoints.up('md')]: {
      boxShadow: isHighlighted ? `inset ${spacing('xs')} 0px ${palette.primary.main}` : '',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: spacing('xs'),
  },
  MenuItemText: {
    overflow: 'hidden',
    whiteSpace: 'wrap',
    textOverflow: 'ellipsis',
    maxWidth: spacing('xxl'),
    mt: `-${spacing(0.7)}`,
    fontSize: font.size.xs,
    lineHeight: spacing(2), // font.lineHeight.large,
    [breakpoints.down('md')]: { padding: `0 ${spacing('xs')}` },
  },
});

const MenuItemWrapper = ({ options, children, ...props }) => {
  const { iconType, onClick, href, useNextRouter } = options;

  return itemHasSubMenu(iconType) ? (
    <Box onClick={onClick} data-testid={`${iconType}_WithSubmenu`} {...props}>
      {children}
    </Box>
  ) : (
    <Link href={href} data-testid={`${iconType}_NoSubmenu`} {...props} underline="none" useNextLink={useNextRouter}>
      {children}
    </Link>
  );
};

export const MenuItem = ({ iconType }) => {
  const { asPath } = useRouter();
  const { setDrawer } = useSubMenuDrawer();
  const { t } = useTranslation(NAMESPACE_COMMON);

  const isHighlighted = itemShouldHighlight(iconType, asPath);
  const classes = createStyles(useTheme(), isHighlighted);

  const { href, linkType, iconTextKey } = MENU_ITEM_INFO[iconType];

  const wrapperOptions = {
    iconType,
    onClick: () => setDrawer(iconType), // only used if icon has a submenu
    href: useFormatLink({ href, linkType }), // only used if icon does not have submenu
    useNextRouter: true, // only use next router for released C2N pages
  };

  return (
    <MenuItemWrapper options={wrapperOptions} sx={classes.MenuItemWrapper}>
      <MenuButton iconType={iconType} />
      <Typography sx={classes.MenuItemText} variant="bodyStaticXs" display="block" gutterBottom>
        {t(iconTextKey)}
      </Typography>
    </MenuItemWrapper>
  );
};
