import Box from '@mui/material/Box';
import Slide, { SlideProps } from '@mui/material/Slide';
import { SnackbarProvider } from 'notistack';
import { FC, PropsWithChildren } from 'react';

const TransitionDown = (props: SlideProps) => <Slide {...props} direction="down" />;

export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => (
  <Box
    sx={({ spacing }) => ({
      '.SnackbarContainer-root': {
        mt: spacing('l'),
      },
    })}
  >
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      dense
      TransitionComponent={TransitionDown}
    >
      {children}
    </SnackbarProvider>
  </Box>
);
