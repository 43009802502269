import getConfig from 'next/config';
import { useRouter } from 'next/router';

import {
  baseURL,
  C2N_SITE_ROUTES_VALUES,
  edgeCaseURLFormatters,
  LANGUAGE_QUERY_KEYS,
  LINK_TYPE,
  prependPath,
} from '@/constants/global';

const {
  publicRuntimeConfig: { ENV },
} = getConfig();

const dummyURL = 'https://www.shutterstock.com';

const supportCenterLanguageMapping = {
  nb: 'no',
  'zh-Hant': 'zh_TW',
  zh: 'zh_TW',
  nl: 'nl_NL',
  pt: 'pt_BR',
};

// some wrapper functions to use, if certain calls of formatLink are very common
// TODO: After CC-WEB decommission rename change LINK_TYPE to EXTERNAL_LINK?
export const createReferralsUrl = (contributorId) =>
  formatLink({ href: `?rid=${contributorId}`, linkType: LINK_TYPE.CCWEB_REDIRECT });

export const createProfileCdnUrl = (imagePath) => formatLink({ href: imagePath, linkType: LINK_TYPE.PICDN });

// TODO: Place this function in the new link component
// https://shutterstock-jira.codefactori.com/browse/CONTRIB-903

/**
 * @param {Object} linkInfo
 * @param {string} linkInfo.href The path of the link, or a full URL (if no 'linkType' value specified). May include search parameters.
 * @param {Symbol=} linkInfo.linkType Determines the base URL to use with the 'href' parameter, and determines how language is formatted. If no type specified, href returned as-is.
 * @param {Object=} linkInfo.queries Object with key-value pairs to add as query parameters to the formatted URL
 * @param {string=} linkInfo.locale 'en', 'fr', etc.
 * @returns {string}
 */
export const formatLink = (linkInfo) => {
  const { href, linkType, queries, locale } = linkInfo;
  let correctLocale = locale;

  // The account links are handled in server.ts using middleware as they require authentication
  if (!linkType || linkType === LINK_TYPE.ACCOUNTS) {
    return queries ? addQueriesToPath(linkInfo) : href;
  }

  // to ensure that CCWeb redirecting does not happen in dev environment
  // for pages that have an equivalent C2N page currently in development
  if (ENV === 'dev' && linkType === LINK_TYPE.CCWEB_REDIRECT && C2N_SITE_ROUTES_VALUES.includes(href)) {
    return href;
  }

  if (edgeCaseURLFormatters[linkType]) {
    const formattedURL = edgeCaseURLFormatters[linkType](linkInfo);

    if (linkInfo.href) {
      return `${formattedURL}${linkInfo.href}`;
    }

    return formattedURL;
  }

  const urlPath = (prependPath[linkType]?.(locale) ?? '') + href;
  const formattedLink = new URL(urlPath, baseURL[linkType]);

  if (queries) Object.entries(queries).forEach(([key, value]) => formattedLink.searchParams.set(key, value));

  if (linkType === LINK_TYPE.CONTRIBUTOR_SUPPORT_CENTER && Object.keys(supportCenterLanguageMapping).includes(locale)) {
    correctLocale = supportCenterLanguageMapping[correctLocale];
  }

  if (LANGUAGE_QUERY_KEYS[linkType] && !!correctLocale) {
    formattedLink.searchParams.set(LANGUAGE_QUERY_KEYS[linkType], correctLocale);
  }

  return formattedLink.toString();
};

/**
 * @param {Object} linkInfo
 * @param {string=} linkInfo.href The path of the link, or a full URL (if no 'linkType' value specified). May include search parameters.
 * @param {Symbol=} linkInfo.linkType Determines the base URL to use with the 'href' parameter, and determines how language is formatted. If no type specified, href returned as-is.
 * @param {Object=} linkInfo.queries Object with key-value pairs to add as query parameters to the formatted URL
 * @returns {string}
 */
export const useFormatLink = (linkInfo) => {
  const { locale } = useRouter();

  if (!linkInfo) return linkInfo;

  return formatLink({ locale, ...linkInfo });
};

const addQueriesToPath = ({ href, queries }) => {
  // can't create a URL object from just a relative path - adding dummyURL as base
  const url = new URL(href, dummyURL);

  Object.entries(queries).forEach(([key, value]) => url.searchParams.set(key, value));

  // removing dummy URL
  return url.pathname + url.search + url.hash;
};
