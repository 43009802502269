import { format } from 'url';

import {
  EARNINGS_ADJUSTMENTS,
  EARNINGS_AGGREGATE_ALLTIME,
  EARNINGS_BY_DATE,
  EARNINGS_GALLERY,
  EARNINGS_GRAPH,
  EARNINGS_HEADER_URL,
  EARNINGS_HIGHLIGHTS_URL,
  EARNINGS_LEVELS,
  EARNINGS_MEDIA_SUMMARY_URL,
  EARNINGS_OVERVIEW_URL,
  EARNINGS_TOP_PERFORMERS,
} from '@/constants/api';

interface earningsQuery {
  mediaType?: string;
  displayColumn?: string;
  perPage?: number;
  page?: number;
  date?: string;
}

export const generateQuery = (earningsQuery: earningsQuery, isMediaSummary?: boolean) => {
  const query: { [key: string]: string | number } = {};

  if (earningsQuery.mediaType) {
    query.media_type = earningsQuery.mediaType;
  }

  if (earningsQuery.date != null) {
    query.date = earningsQuery.date;
  }

  if (earningsQuery.displayColumn != null && !isMediaSummary) {
    query.display_column = earningsQuery.displayColumn;
  }

  if (earningsQuery.page) {
    query.page = earningsQuery.page;
  }

  if (earningsQuery.perPage) {
    query.per_page = earningsQuery.perPage;
  }

  return query;
};

export const getEarningsHeaderUrl = () => ({
  url: EARNINGS_HEADER_URL,
  formattedUrl: format({ pathname: EARNINGS_HEADER_URL }),
});

export const getEarningsHighlightsUrl = () => ({
  url: EARNINGS_HIGHLIGHTS_URL,
  formattedUrl: format({ pathname: EARNINGS_HIGHLIGHTS_URL }),
});

export const getEarningsMediaSummaryUrl = ({
  mediaType = 'photo',
  displayColumn = '',
  page = 1,
  perPage = 10,
}: {
  mediaType?: string;
  displayColumn?: string;
  page?: number;
  perPage?: number;
}) => ({
  url: EARNINGS_MEDIA_SUMMARY_URL,
  formattedUrl: format({
    pathname: EARNINGS_MEDIA_SUMMARY_URL,
    query: generateQuery({ mediaType, displayColumn, page, perPage }, true),
  }),
});

export const getEarningsByDateUrl = ({
  date = '',
  displayColumn = '',
  page = 1,
  perPage = 10,
}: {
  date?: string;
  displayColumn?: string;
  page?: number;
  perPage?: number;
}) => ({
  url: EARNINGS_BY_DATE,
  formattedUrl: format({
    pathname: EARNINGS_BY_DATE,
    query: generateQuery({ date, displayColumn, page, perPage }),
  }),
});

export const getEarningsByMonthUrl = ({ year, month }: { year?: number; month?: number }) => {
  const query = { aggregation_period: 'day', year, month };

  return {
    url: EARNINGS_OVERVIEW_URL,
    formattedUrl: format({ pathname: EARNINGS_OVERVIEW_URL, query }),
  };
};

export const getAdjustmentsUrl = ({ page = 1, perPage = 10 }) => ({
  url: EARNINGS_ADJUSTMENTS,
  formattedUrl: format({
    pathname: EARNINGS_ADJUSTMENTS,
    query: { page, per_page: perPage },
  }),
});

export const getEarningsGraphUrl = ({
  year,
  month,
  aggregationPeriod,
  monthsAgo,
  groupBy = [''],
  locale,
}: {
  year: number;
  month: number;
  aggregationPeriod: string;
  monthsAgo: number;
  groupBy: string[];
  locale: string;
}) => {
  const externalParams = new URLSearchParams();

  externalParams.append('year', year.toString());
  externalParams.append('month', month.toString());
  externalParams.append('aggregation_period', aggregationPeriod);
  externalParams.append('months_ago', monthsAgo.toString());
  const internalParams = new URLSearchParams();

  internalParams.append('year', year.toString());
  internalParams.append('month', month.toString());
  internalParams.append('aggregation_period', aggregationPeriod);
  internalParams.append('months_ago', monthsAgo.toString());
  internalParams.append('locale', locale);

  if (groupBy.length) {
    externalParams.append('group_by', groupBy.toString());
    internalParams.append('group_by', groupBy.toString());
  }

  return {
    url: `${EARNINGS_OVERVIEW_URL}?${externalParams}`,
    internalUrl: `${EARNINGS_GRAPH}?${internalParams}`,
  };
};

export const getEarningsAggregateAlltimeUrl = ({ groupBy }: { groupBy: string }) => ({
  url: EARNINGS_AGGREGATE_ALLTIME,
  formattedUrl: format({
    pathname: EARNINGS_AGGREGATE_ALLTIME,
    query: { group_by: groupBy },
  }),
});

export const getEarningsLevelsUrl = (searchParam: { year: string }) => ({
  url: `${EARNINGS_LEVELS}?${new URLSearchParams(searchParam).toString()}`,
});

export const getEarningsGalleryUrl = ({
  mediaType, // photo or video
  page = 1,
  perPage = 10,
  sort = 'desc', // asc or desc
  monthsAgo,
  keywordsPerMedia,
  setId,
}: {
  mediaType: string;
  page?: number;
  perPage?: number;
  sort?: string;
  monthsAgo?: number;
  keywordsPerMedia?: number;
  setId?: number;
}) => {
  const query = new URLSearchParams();

  query.append('per_page', perPage.toString());
  query.append('page', page.toString());
  query.append('sort', sort);

  // What media type to query: photo, video.
  // If this parameter is absent then CC-API returns both photo and video.
  if (mediaType) {
    query.append('media_type', mediaType);
  }

  // How many previous months of data we want to see
  // If this parameter is absent then CC-API returns all time top earners
  // It not is impossible to query current month’s data with this endpoint
  if (monthsAgo) {
    query.append('months_ago', monthsAgo.toString());
  }

  // Maximum keywords to include per asset
  // If this parameter is absent then CC-API returns all keywords
  if (keywordsPerMedia != null) {
    query.append('keywords_per_media', keywordsPerMedia.toString());
  }

  // If this parameter is present then CC-API limits the assets to specific set ID (collection ID)
  // This parameter also overrides mediaType
  if (setId != null) {
    query.append('set_id', setId.toString());
  }

  return {
    url: EARNINGS_GALLERY,
    formattedUrl: `${EARNINGS_GALLERY}?${query}`,
  };
};

export const getEarningsTopPerformersUrl = ({
  monthsAgo,
  mediaType = 'photo',
}: {
  monthsAgo?: number;
  mediaType: string;
}) => {
  const query = new URLSearchParams();

  query.append('media_type', mediaType);
  query.append('keywords_per_media', '5');

  if (monthsAgo) {
    query.append('months_ago', monthsAgo.toString());
  }

  return {
    url: EARNINGS_TOP_PERFORMERS,
    formattedUrl: `${EARNINGS_TOP_PERFORMERS}?${query}`,
  };
};
