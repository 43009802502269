import { ENV_KEYS, ENVS } from './constants';

/**
 * Validate the provided environment string
 * @param env
 * @returns
 */
const isValidEnv = (env: string | undefined): env is keyof typeof ENVS => env !== undefined && env in ENVS;

/**
 *
 * @returns
 */
export const getSDKEnvArgs = () => {
  const currentEnv = process.env[ENV_KEYS.sdkEnv];

  return {
    env: isValidEnv(currentEnv) ? ENVS[currentEnv] : ENVS.dev,
    // TODO proper secret handling for the hardcoded experiment
    // https://shutterstock-jira.codefactori.com/browse/CONTRIB-4898
    sdkKey: process.env[ENV_KEYS.sdkKey] || 'secret-S17YOBxYHIdm36xaLAmcUDlpiQtcOdoZJXTvRDhyFIx',
    // allows running switchr, but without network requests ie. no real data
    // localMode: true;
  };
};
