export const noop = (): void => {};

export const nullReturnNoop = (): null => null;

export const noopAsync = () => Promise.resolve(undefined);

export const emptyObject = Object.freeze({});

export const emptyArray: readonly unknown[] = Object.freeze([]);

export const emptyObjectReturnNoop = () => emptyObject;
