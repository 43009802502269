import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/WithTracking/Button';
import { CommonPageSections, CommonTrackActions } from '@/constants/analytics';
import { useConfirmationComponent } from '@/hooks/useConfirmationComponent';
import { usePageFormState } from '@/hooks/usePageFormState';
import { PageSection } from '@/lib/analytics/react-data-tracking';
import { NAMESPACE_COMMON } from '@/translations/namespaces';

const actionButtonStyle = ({ spacing }: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  mt: spacing(5.25),
});

export const UnsavedModalContent = ({
  onClose = () => {},
  href = null,
}: {
  onClose?: () => void;
  href: string | null;
}) => {
  const { t } = useTranslation(NAMESPACE_COMMON);

  const { setPageHasDirtyForm } = usePageFormState();

  // @ts-ignore: closeConfirmation is not infered correctly from a JS file
  const { closeConfirmation }: { closeConfirmation: () => void } = useConfirmationComponent();
  const router = useRouter();

  const leave = async () => {
    setPageHasDirtyForm(false);
    onClose();

    // Handle global navigation
    if (href) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push(href);
    }

    closeConfirmation();
  };

  return (
    <PageSection value={CommonPageSections.unsavedDialog}>
      <Box data-testid="unsaved-changes-modal">
        <Typography id="modal-title" data-testid="modal-title" variant="h4" component="h2">
          {t('unsaved_changes')}
        </Typography>
        <Typography id="modal-description" variant="body1" sx={{ mt: 2 }}>
          {t('unsaved_changes_content')}
        </Typography>
        <Box sx={actionButtonStyle}>
          <Button variant="secondary" onClick={closeConfirmation} trackAction={CommonTrackActions.stay}>
            {t('unsaved_changes_stay')}
          </Button>
          <Button onClick={leave} trackAction={CommonTrackActions.leave}>
            {t('unsaved_changes_leave')}
          </Button>
        </Box>
      </Box>
    </PageSection>
  );
};
